import React, { useState, useEffect, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter, browserHistory } from 'react-router';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Tooltip, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
import { leaseStatus, subStatus } from '../';
import { DeletePopUp, GoogleLocationField, LesseeCreate, PageLoader } from '../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { jetStreamInstance } from '../../../shared/components';
import TemplateSelection from './TemplateSelection';
import { Edit, Info } from '@material-ui/icons';
import config from '../../../config';
const AddEngine = ({ portfolios, regions, lessees, operators, engineTypes, pltConstants, addAsset, toggleModalFn, ownersList, location, getResponseBack, permission }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [formData, setFormData] = useState({ ownership_type: 1, same_operator_lessee: false, status:null, sub_status:null });
    const [addTemplateModal, setAddTemplateModal] = useState(false);
    const [defaultTemplate, setDefaultTemplate] = useState({});
    const [noRecordsPermission, setNoRecordsPermission] = useState({ modal: false });
    const [templateData, setTemplateData] = useState(null);
    const [templatPayload, setTemplatPayload] = useState(null);
    const [pageLoader, setPageLoader] = useState(null);

    const onFieldChange = (keyParam, value) => {
        if(keyParam == 'esn' || keyParam === 'engine_type_id'){
            // setFormData(prevState=>({...prevState, add_template:null}))
            setFormData({ ...formData, add_template: null,[keyParam]:value })
            setDefaultTemplate({})
        }
        if (keyParam === 'ownership_type' && jetStreamInstance) {
            setFormData({...formData, [keyParam]: value, 'status': null, 'sub_status':null });
        }else if(keyParam === 'status' && jetStreamInstance){
            setFormData({...formData,[keyParam]:value, 'sub_status':null})
        }else{
            setFormData({ ...formData, [keyParam]: value })
        }
    }
 
    const onFieldChangeLessee = (e, keyParam, value) => {
        setFormData({ ...formData, [keyParam]: value })
    }
    const onRestErrorKey = (keyParam) => {
        setError({ ...error, [keyParam]: '' })
    }
    const getUuid = (data) => {
        setLoading(true)
        globalGetService(`console/${formData?.engine_module_type_id?.value ? formData?.engine_module_type_id?.value?.toLowerCase() : 'engine'}/${data.slug}/records-detail/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    addTemplateonSubmit(templateData?.templateDetails ? templateData : defaultTemplate, data, response.data.data)
                }
            })
    }
    const addTemplateonSubmit = (templateData, assetData, recordsData) => {
        let payload = Object.assign({}, templateData)
        payload = {
            ...payload,
            asset_type: 2,
            folderDetails: payload?.checkedFolders ? [...payload?.checkedFolders] : templateData?.folderDetails,
            asset: payload?.asset ? { ...payload?.asset, id: assetData.id } : { id: assetData.id },
            recordsParentFolder: { ...recordsData?.record_folders?.technical_records, name: "technical" },
            majorAssembly: false,
            majorAssemblyAsset: null,
            majorAssemblyAssetType: null,
            initial_template:true,
            templateDetails: templateData?.templateDetails
        }
        if (payload?.checkedFolders) {
            delete payload['checkedFolders']
            delete payload['selectedTemplate']
        }
        setLoading(true)
        globalPostService(`records/v2/lessor-folder-template/apply-template/${templateData.templateDetails?.id}/`, payload)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLoading(false)
                    setTimeout(() => {
                        window.location.reload()
                    }, 200)
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            });
    }
    const onAddNewAsset = (data) => {
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            esn: formData.esn ? '' : 'Please enter Engine Serial Number (ESN)',
            engine_type_id: formData.engine_type_id ? '' : 'Please select Engine Type',
            status: formData.status ? '' : 'Please select Engine Lease status',
            manufacturing_date: formData.manufacturing_date ? '' : 'Please enter Date of Manufacture',
        }
        if (formData.ownership_type === 2 && !jetStreamInstance) {
            validationInputs = {
                ...validationInputs,
                portfolio: formData.owner_portfolio ? '' : 'Please select Portfolio',
            }
        }
        if (addAsset.asset_type !== 2) {
            validationInputs = {
                ...validationInputs,
                engine_module_type_id: formData.engine_module_type_id ? '' : 'Please select Module Type',
            }
        }
        if (formData?.status?.value == 1) {
            validationInputs = {
                ...validationInputs,
                lessee_id: formData?.lessee_id?.value || formData?.lessee_id?.id ? '' : 'Please select Lessee',
                position: formData.position ? '' : 'Please select Engine Position'
            }
        }
        if (formData.same_operator_lessee === false && formData?.status?.value == 1) {
            validationInputs = {
                ...validationInputs,
                operator_id: formData.operator_id ? '' : 'Please select Operator'
            }
        }
        if (addAsset.asset_type !== 2 && formData?.status?.value == 1) {
            validationInputs = {
                ...validationInputs,
                region: formData.region ? '' : 'Please select Region'
            }
        }
        if (getLocalStorageInfo()?.defaultLessor?.id === 242) {
            validationInputs = {
                ...validationInputs,
                sub_status: formData.sub_status ? '' : 'Please select Sub Status'
            }
        }
        if (getLocalStorageInfo()?.defaultLessor?.id === 242 && formData?.status?.value == 5) {
            validationInputs = {
                ...validationInputs,
                sub_status: ''
            }
        }
        if (formData?.status?.value == 1 && formData?.lessee_id && formData.same_operator_lessee === false && formData?.operator_id) {
            if (formData.lessee_id.name == formData.operator_id.name) {
                validationInputs = {
                    ...validationInputs,
                    operator_id: 'Operator Should be different from Lessee'
                }
            }
        }

        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            if (!permission?.settings?.records_template?.includes('C') && getLocalStorageInfo()?.is_records && (noRecordsPermission?.modal == false)) {
                setNoRecordsPermission({ modal: true })
            }
            else {
                let url = addAsset.asset_type === 2 ? 'console/add-engine/' : 'console/add-' + formData.engine_module_type_id.value.toLowerCase() + '/';
                let payload = Object.assign({}, formData);
                payload = {
                    ...payload,
                    engine_type_id: formData.engine_type_id.id,
                    engine_module_type_id: formData?.engine_module_type_id?.value || null,
                    position: formData?.position?.value || null,
                    status: formData.status.value,
                    sub_status: formData?.sub_status?.value || null,
                    region: formData?.region?.id || null,
                    // lessee_id: formData?.lessee_id?.id || null,
                    operator_id: formData?.operator_id?.value || null,
                    archive_status: payload.status.value == 5 ? true : false
                }
                if (payload.ownership_type === 2 && !jetStreamInstance) {
                    if (payload.owner_portfolio.id) {
                        payload = {
                            ...payload,
                            owner_portfolio: payload.owner_portfolio?.id
                        }
                    }
                }
                let dataCopy = Object.assign({}, formData)
                if (data == 'notify_email') {
                    payload = {
                        ...payload,
                        notify_email: true
                    }
                }
                setLoading(true)
                globalPostService(url, payload)
                    .then(response => {
                        setNoRecordsPermission({ modal: false })
                        if (checkApiStatus(response)) {
                            if (dataCopy?.add_template === true) {
                                getUuid(response.data.data)
                            }
                            else {
                                toggleModalFn();
                            }
                            setLoading(false)
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            trackActivity('Item Added', { page_title: 'Asset List', event_type: `Add ${addAsset.asset_type}`, asset_type: `${addAsset.asset_type}`, asset_name: formData.esn, event_desc: `Added ${addAsset.asset_type} data from Add ${addAsset.asset_type} form` })
                            setTimeout(() => {
                                if (payload.status == 5) {
                                    browserHistory.push('/archived-assets')
                                } else if (addAsset.asset_type === 2) {
                                    if (location.query.apps && location.query.asset_type) {
                                        getResponseBack()
                                    } else {
                                        browserHistory.push(`/technical-specification/engine/${response.data.data.slug}/basic-details`)
                                    }
                                } else if (addAsset.asset_type !== 2) {
                                    if (location.query.apps && location.query.asset_type) {
                                        getResponseBack()
                                    } else {
                                        browserHistory.push(`/technical-specification/${formData?.engine_module_type_id?.value?.toLowerCase()}/${response.data.data.slug}/basic-details`)
                                    }
                                } else {
                                    window.location.reload(false)
                                }
                            }, 800);
                        } else {
                            setLoading(false)
                        }
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    const fetchTemplate = (flag) => {
        if (flag) {
            let payload = {}
            payload = {
                ...payload,
                asset_type: addAsset.asset_type,
                asset: formData.engine_type_id,
                asset_model_type: formData.engine_type_id,
                majorAssemblyAssetType: null,
                majorAssemblyAsset: {},
                position: formData.position || null,
                is_major_assembly: false,
                serial_number: formData.esn
            }
            setPageLoader(true)
            globalPostService(`records/v2/get-lessor-folder-template/`, payload)
                .then(response => {
                    setPageLoader(false)
                    setTemplatPayload(payload)
                    if (checkApiStatus(response)) {
                        if (response.data.data) {
                            setDefaultTemplate(response.data.data)
                        }
                    }
                    else {
                        setAddTemplateModal({ modal: true, data: {}, apiCall: 'apicall' })
                    }
                })
        }
        else {
            setDefaultTemplate({})
        }
    }
    const getTemplateDetailsFn = () => {
        setAddTemplateModal({ modal: true, data: defaultTemplate })
    }
    const saveTemplateData = (data) => {
        setTemplateData(data)
    }
    const checkTemplateData =(value) =>{
        if(!value){
            setTemplateData({}) 
        }
    }
    const checkForDefaultTemp =()=>{
        if( !defaultTemplate?.templateDetails){
            onFieldChange('add_template',false)
        }
    }
    const smbcInstance =  getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 );
    const { jacLeaseStatus, defaultLeaseStatus, airAsiaLeaseStatus , thirdParty} = leaseStatus;
    let jetstreamOption;
    if (formData?.status?.value == 1) {
        jetstreamOption = subStatus.onLease;
    } else if (formData?.status?.value == 2) {
        if (formData.ownership_type == 2) {
            jetstreamOption = subStatus.offLease.filter(item => item.value !== '25');
        } else {
            jetstreamOption = subStatus.offLease;
        }
    } else if (formData?.status?.value == 17) {
        jetstreamOption = subStatus.sold;
    } else if (formData?.status?.value == 14) {
        jetstreamOption = subStatus.partOut;
    } else if (formData?.status?.value == 15) {
        jetstreamOption = subStatus.installedOnly;
    } else if (formData?.status?.value == 16) {
        jetstreamOption = subStatus.writtenOff;
    } else {
        jetstreamOption = pltConstants.filter(item => item.type === 'asset_sub_status');
    }
  return (
        <Fragment>
            {pageLoader ? <PageLoader /> : null}

            <Dialog
                open={addAsset.modal}
                onClose={() => toggleModalFn()}
                className='console-management-modal'
                maxWidth={'md'}
            >
                <DialogTitle id="scroll-dialog-title">
                    Add {addAsset.asset_type === 2 ? 'Engine' : 'Engine Module'}
                </DialogTitle>
                <DialogContent dividers={true} className="data-upload-modal">
                    <form>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    id="esn"
                                    label="ESN"
                                    fullWidth
                                    margin="normal"
                                    value={formData.esn}
                                    error={error.esn ? true : false}
                                    helperText={error.esn ? error.esn : ''}
                                    onChange={(e, value) => onFieldChange('esn', e.target.value)}
                                    onFocus={() => onRestErrorKey('esn')}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 20 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={engineTypes}
                                    getOptionLabel={option => option.name}
                                    id="engine_type_id"
                                    value={formData.engine_type_id}
                                    onChange={(e, value) => onFieldChange('engine_type_id', value)}
                                    renderInput={params => <TextField required error={error.engine_type_id ? true : false} helperText={error.engine_type_id ? error.engine_type_id : ''} onFocus={() => onRestErrorKey('engine_type_id')} {...params} label="Engine Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            {addAsset.asset_type !== 2 ?
                                <Grid item xs={12} md={4}>
                                    <Autocomplete
                                        options={[{ label: 'FAN', value: 'Fan' }, { label: 'HPC', value: 'HPC' }, { label: 'HPT', value: 'HPT' }, { label: 'LPT', value: 'LPT' }]}
                                        getOptionLabel={option => option.label}
                                        id="engine_module_type_id"
                                        value={formData.engine_module_type_id}
                                        onChange={(e, value) => onFieldChange('engine_module_type_id', value)}
                                        renderInput={params => <TextField required error={error.engine_module_type_id ? true : false} helperText={error.engine_module_type_id ? error.engine_module_type_id : ''} onFocus={() => onRestErrorKey('engine_module_type_id')} {...params} label="Module Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid> : null
                            }
                            <Grid item xs={12} md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        required
                                        margin="normal"
                                        id="manufacturing_date"
                                        label="Date of Manufacture"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        minDate={moment().subtract(25, 'years')}
                                        value={formData.manufacturing_date ? formData.manufacturing_date : null}
                                        error={error.manufacturing_date ? true : false}
                                        helperText={error.manufacturing_date ? error.manufacturing_date : ''}
                                        onChange={(data, value) => { onFieldChange('manufacturing_date', moment(data).format(backendDateFormat)); onRestErrorKey('manufacturing_date') }}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            { !smbcInstance ?
                                <Grid item xs={12} md={4}>
                                    <Autocomplete
                                        options={regions}
                                        getOptionLabel={option => option.name}
                                        id="region"
                                        value={formData.region}
                                        onChange={(e, value) => onFieldChange('region', value)}
                                        renderInput={params => <TextField required={addAsset.asset_type !== 2 && formData?.status?.value == 1 ? true : false} {...params} label="Region of Operation" margin="normal" fullWidth InputLabelProps={{ shrink: true }} error={error.region ? true : false} helperText={error.region ? error.region : ''} onFocus={() => onRestErrorKey('region')} variant='outlined' />}
                                    />
                                </Grid> : null
                            }
                            <Grid item xs={12} md={4}>
                                <GoogleLocationField
                                    onFieldChange={onFieldChange}
                                    location={formData.location ? formData.location : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={smbcInstance?pltConstants.filter(item => item.type === 'engine_position').filter((item)=>[0,1,2].includes(item.value)):pltConstants.filter(item => item.type === 'engine_position')}
                                    getOptionLabel={option => option.label}
                                    id="position"
                                    value={formData.position}
                                    onChange={(e, value) => onFieldChange('position', value)}
                                    renderInput={params => <TextField {...params} required={formData?.status?.value == 1 ? true : false} label="Position" margin="normal" fullWidth InputLabelProps={{ shrink: true }} error={error.position ? true : false} helperText={error.position ? error.position : ''} onFocus={() => onRestErrorKey('position')} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl component="fieldset">
                                    <FormLabel className="MuiInputLabel-shrink" component="label">Ownership Status</FormLabel>
                                    <RadioGroup row aria-label="position" name="ownership_type" defaultValue="top">
                                        <FormControlLabel value={1} control={<Radio checked={formData.ownership_type === 1} onChange={() => onFieldChange('ownership_type', 1)} color="primary" size="small" />} label="Owned" />
                                        <FormControlLabel value={2} control={<Radio checked={formData.ownership_type === 2} onChange={() => onFieldChange('ownership_type', 2)} color="primary" size="small" />} label="Managed" />
                                        {jetStreamInstance?<FormControlLabel value={3} control={<Radio checked={formData.ownership_type === 3} onChange={() => onFieldChange('ownership_type', 3)} color="primary" size="small" />} label="Third party"/>:null} 
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={getLocalStorageInfo().defaultLessor.id === 348 ? airAsiaLeaseStatus : getLocalStorageInfo().defaultLessor.id === 442 &&formData.ownership_type === 3 ?thirdParty :getLocalStorageInfo().defaultLessor.id === 442 && formData.ownership_type !== 3?jacLeaseStatus: defaultLeaseStatus}
                                    getOptionLabel={option => option.label}
                                    id="status"
                                    value={formData.status}
                                    onChange={(e, value) => onFieldChange('status', value)}
                                    renderInput={params => <TextField required error={error.status ? true : false} helperText={error.status ? error.status : ''} onFocus={() => onRestErrorKey('status')} {...params} label="Lease Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            {formData?.status?.value == 4 ?
                                <Grid item xs={12} md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="sold_out_date"
                                            label="Date of Sale"
                                            format={fieldDateFormat}
                                            fullWidth
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            value={formData.sold_out_date ? formData.sold_out_date : null}
                                            onChange={(data, value) => onFieldChange('sold_out_date', data ? moment(data).format(backendDateFormat) : null)}
                                            minDate={(moment(formData.manufacturing_date))}
                                            maxDate={moment()}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid> : null
                            }
                            {pltConstants.filter(item => item.type === 'asset_sub_status').length && formData?.status?.value == 5 ? null :
                                <Grid item xs={12} md={4}>
                                    <Autocomplete
                                        options = {jetStreamInstance?jetstreamOption:pltConstants.filter(item => item.type === 'asset_sub_status') }
                                        getOptionLabel={option => option.label}
                                        id="sub_status"
                                        value={formData.sub_status}
                                        onChange={(e, value) => onFieldChange('sub_status', value)}
                                        renderInput={params => <TextField required={getLocalStorageInfo()?.defaultLessor?.id === 242 ? true : false} error={error.sub_status ? true : false} helperText={error.sub_status ? error.sub_status : ''} onFocus={() => onRestErrorKey('sub_status')} {...params} label="Sub Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid>
                            }
                            {formData.ownership_type === 2 && !jetStreamInstance?
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={ownersList}
                                        getOptionLabel={option => option.name}
                                        id="owner_portfolio"
                                        value={formData.owner_portfolio}
                                        onChange={(e, value) => onFieldChange('owner_portfolio', value)}
                                        renderInput={params => <TextField error={error.portfolio ? true : false} required helperText={error.portfolio ? error.portfolio : ''} onFocus={() => onRestErrorKey('portfolio')} {...params} label="Portfolio" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid> : null
                            }

                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="tsn"
                                    label="TSN"
                                    fullWidth
                                    margin="normal"
                                    value={formData.tsn ? formData.tsn : ''}
                                    onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault() }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="csn"
                                    label="CSN"
                                    fullWidth
                                    margin="normal"
                                    value={formData.csn ? formData.csn : ''}
                                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault() }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="average_monthly_hours"
                                    label="Avg. Monthly Hours"
                                    fullWidth
                                    margin="normal"
                                    value={formData.average_monthly_hours ? formData.average_monthly_hours : ''}
                                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('average_monthly_hours', e.target.value) : e.preventDefault() }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="average_monthly_cycles"
                                    label="Avg. Monthly Cycles"
                                    fullWidth
                                    margin="normal"
                                    value={formData.average_monthly_cycles ? formData.average_monthly_cycles : ''}
                                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('average_monthly_cycles', e.target.value) : e.preventDefault() }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            {formData?.status?.value == 1 ?
                                <>
                                    <Grid item xs={12} md={4}>
                                        <LesseeCreate
                                            options={lessees}
                                            required={formData?.status?.value == 1 ? true : false}
                                            paramsKey='lessee_id'
                                            optionKey='name'
                                            label='Lessee'
                                            value={formData.lessee_id ? formData.lessee_id : null}
                                            onFieldChange={(e, paramsKey, newValue) => onFieldChangeLessee(e, 'lessee_id', newValue)}
                                            error={error.lessee_id}
                                            resetErrorKey={() => onRestErrorKey('lessee_id')}
                                            disabled={formData?.status?.value != 1 ? true : false}
                                        />
                                        {/* <Autocomplete
                                    options={lessees}
                                    getOptionLabel={option => option.name}
                                    id="lessee_id"
                                    value={formData.lessee_id}
                                    onChange={(e, value) => onFieldChange('lessee_id', value)}
                                    renderInput={params => <TextField error={error.lessee_id ? true : false} required={formData?.status?.value == 1 ? true : false} helperText={error.lessee_id ? error.lessee_id : ''} onFocus={() => onRestErrorKey('lessee_id')} {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    disabled={formData?.status?.value != 1 ? true : false}
                                /> */}
                                    </Grid>
                                    <Grid item md={12} xs={12} >
                                        <FormControl component="fieldset">
                                            <FormLabel className="MuiInputLabel-shrink" component="label">Is Lessee also the Operator?</FormLabel>
                                            <RadioGroup row aria-label="position" name="same_operator_lessee" >
                                                <FormControlLabel value={true} control={<Radio onChange={() => onFieldChange('same_operator_lessee', true)} checked={formData.same_operator_lessee === true} color="primary" size="small" />} label="Yes" />
                                                <FormControlLabel value={false} control={<Radio onChange={() => onFieldChange('same_operator_lessee', false)} checked={formData.same_operator_lessee === false} color="primary" size="small" />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {formData.same_operator_lessee === false ?
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                options={operators}
                                                getOptionLabel={option => option.name}
                                                id="operator_id"
                                                value={formData.operator_id}
                                                onChange={(e, value) => onFieldChange('operator_id', value)}
                                                renderInput={params => <TextField required={formData.same_operator_lessee === false ? true : false} error={formData.same_operator_lessee === false ? error.operator_id ? true : false : false} helperText={formData.same_operator_lessee === false ? error.operator_id : ''} onFocus={() => onRestErrorKey('operator_id')} {...params} label="Operator" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                            />
                                        </Grid> : null
                                    }
                                </> : null
                            }
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="operating_thrust"
                                    label="Operating Thrust (Lbs)"
                                    fullWidth
                                    margin="normal"
                                    value={formData.operating_thrust ? formData.operating_thrust : ''}
                                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('operating_thrust', e.target.value) : e.preventDefault() }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="purchase_thrust"
                                    label="Purchased Thrust (Lbs)"
                                    fullWidth
                                    margin="normal"
                                    value={formData.purchase_thrust ? formData.purchase_thrust : ''}
                                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('purchase_thrust', e.target.value) : e.preventDefault() }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox checked={formData.match_csn_to_llp} onChange={(e) => onFieldChange('match_csn_to_llp', e.target.checked)} color="primary" size="small" />} label={`Match ${addAsset.asset_type === 2 ? 'Engine\'s' : 'Module'} TSN & CSN to LLP`} />
                            </Grid>
                            {
                                getLocalStorageInfo()?.is_records ?
                                    <Grid item xs={12} >
                                        <FormControlLabel control={<Checkbox disabled={formData.engine_type_id && formData?.esn && permission?.settings?.records_template?.includes('C') ? false : true} color="primary" size="small" checked={formData?.add_template ? true : false} onChange={(e) => { onFieldChange('add_template', e.target.checked); fetchTemplate(e.target.checked);checkTemplateData(e.target.checked) }} name="add_template" />} label={<p>
                                            <span>Add Dataroom Template</span>
                                            <Tooltip title='Please click on “Add Dataroom Template” to choose a template and apply it to this asset’s Dataroom.' arrow>
                                                <Info style={{ fontSize: '13px', marginLeft: '3px' }} />
                                            </Tooltip>
                                        </p>} />
                                        {
                                            defaultTemplate?.templateDetails && formData?.add_template ?
                                                <Chip onDelete={getTemplateDetailsFn} deleteIcon={<Edit/>} variant="outlined" color="primary" label={defaultTemplate?.templateDetails?.name} size='small' onClick={() => getTemplateDetailsFn()} /> : null
                                        }
                                        <br/>
                                        <span className='notes-yellow'>
                                            {
                                                permission?.settings?.records_template?.includes('C') ?
                                                'Add ESN and Engine Type to enable Dataroom Template': 
                                                'You do not have the necessary permissions to enable the Add Dataroom Template.'
                                            }
                                        </span>
                                    </Grid>
                                    : null}
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => { toggleModalFn(); trackActivity('Item Clicked', { page_title: 'Asset List', event_type: 'Cancel', event_desc: ` Clicked on Cancel Button from Add ${addAsset.asset_type} form` }) }}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={() => { onAddNewAsset(); trackActivity('Item Clicked', { page_title: 'Asset List', event_type: 'Save ', event_desc: `Clicked on Save Button from Add ${addAsset.asset_type} form` }) }}>
                        {isLoading ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            {
                addTemplateModal ?
                    <TemplateSelection
                        setDefaultTemplate={setDefaultTemplate}
                        templatPayload={templatPayload}
                        addTemplateModal={addTemplateModal}
                        templateData={templateData}
                        assetData={{ ...formData, asset_type: addAsset.asset_type }}
                        templateInitialData={addTemplateModal?.data}
                        open={addTemplateModal}
                        onSubmitData={(data, tempId) => { saveTemplateData(data); setAddTemplateModal(false) }}
                        toggleModalFn={() => {setAddTemplateModal(false); checkForDefaultTemp()}}
                    />
                    : null
            }
            {
                noRecordsPermission.modal ?
                    <DeletePopUp
                        modal={noRecordsPermission.modal}
                        title='Add Engine'
                        confirmColor='primary'
                        confirmText={isLoading ? <CircularProgress color="#ffffff" size={24} />:'Save' }
                        content={
                            <span>
                                Asset Creation Recquires Records Action, You do not have a Records Application Permission to Perform.
                                <div style={{ marginTop: '15px' }}>
                                    Are you sure, you want to Continue?
                                </div>
                            </span>}
                        toggleModalFn={() => toggleModalFn({ modal: false, data: null })}
                        deleteRecordFn={()=>onAddNewAsset('notify_email')}
                    /> : null
            }
        </Fragment>
    )
}

export default withRouter(AddEngine);

import { globalPostService } from "../../globalServices";
import { checkApiStatus } from "../utils_v2";
import config from "../../config";
import { setGlobalCookie } from "../../utils";
import { browserHistory } from "react-router";
const enableAssetListing = (lessorId,token,setLoading) => {
    setLoading(true)
    globalPostService('api/user-authorization/', {lessor_id: lessorId,accessToken:token, lite:true, permissions:['console', 'contracts', 'is_records', 'is_technical', 'is_maintenance', 'is_camo', 'is_mr_calculator', 'is_valuation']})
        .then(response => {
            setLoading(false)
            if (checkApiStatus(response)) {
                let userDetail = response.data.data;
                userDetail = {
                    ...userDetail,
                    access: response.data.data.lessor_access_token,
                    defaultLessor: response.data.data.current_lessor,
                    user: response.data.data.user,
                    newKey:true
                }
                let baseDomain = '.sparta.aero', expireAfter = new Date();
                localStorage.setItem('userInfo', JSON.stringify(userDetail));
                let cookieData = JSON.stringify({ access: response.data.data.lessor_access_token, id: response.data.data.current_lessor.id, refresh: '', environment: config.env.key, version: process.env.REACT_APP_VERSION });
                let cookieName = JSON.stringify({ name: response.data.data.user.name, designation: response.data.data.user.designation });
                setGlobalCookie('userName', cookieName, 3);
                setGlobalCookie("lessorAccess", cookieData, 3);
                setGlobalCookie("domain", baseDomain, 3);
                let currentLocation = browserHistory.getCurrentLocation();
                if(currentLocation?.query?.apps && config.domain[currentLocation?.query?.apps]){
                    let appUrl = config.domain[currentLocation?.query?.apps];
                    window.open(appUrl,'_self')
                }else{
                    browserHistory.push('/');
                    window.location.reload()
                }
            }
        });
}

export default enableAssetListing;
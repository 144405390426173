import React, { useState, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter, browserHistory } from 'react-router';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Tooltip, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import { globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
import { leaseStatus, subStatus } from '../';
import { GoogleLocationField, LesseeCreate, PageLoader } from '../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { jetStreamInstance } from '../../../shared/components';
const AddComponenet = ({ regions, lessees, operators, pltConstants, addAsset, toggleModalFn, location, getResponseBack, permission }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [formData, setFormData] = useState({ same_operator_lessee: false, status: null, sub_status: null });
    const [pageLoader, setPageLoader] = useState(null);

    const onFieldChange = (keyParam, value) => {
        if (keyParam === 'ownership_type') {
            setFormData({ ...formData, [keyParam]: value, 'status': null, 'sub_status': null });
        } else if (keyParam === 'status') {
            setFormData({ ...formData, [keyParam]: value, 'sub_status': null ,'sold_out_date': null})
        } else {
            setFormData({ ...formData, [keyParam]: value })
        }
    }

    const onFieldChangeLessee = (e, keyParam, value) => {
        setFormData({ ...formData, [keyParam]: value })
    }
    const onRestErrorKey = (keyParam) => {
        setError({ ...error, [keyParam]: '' })
    }
    const onAddNewAsset = (data) => {
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            sold_out_date: formData?.date_of_manufacture && formData?.sold_out_date ? moment(formData?.sold_out_date).isSameOrAfter(formData?.date_of_manufacture, 'day') ? '' : "Date of Sale cannot be lesser than Date Of Manufacture" : '',
            status: formData.status ? '' : 'Please select Lease status',
            component_name: formData?.component_name?.trim()?.length ? '' : 'Please enter Component Name ',
            serial_number: formData?.serial_number?.trim()?.length ? '' : 'Please enter Component Serial Number',
            ownership_type: formData?.ownership_type ? '' : 'Please select Ownership Type',
            date_of_manufacture:  formData?.date_of_manufacture ? moment(formData.date_of_manufacture).isValid() && moment(formData?.date_of_manufacture).isSameOrBefore(moment(), 'day') ? '' : "Date of Manufacture cannot be future date" : 'Please enter Date of Manufacture',

        }
        if (formData?.status?.value == 1) {
            validationInputs = {
                ...validationInputs,
                lessee: formData?.lessee ? '' : 'Please select Lessee',
            }
        }

        if (formData.same_operator_lessee === false && formData?.status?.value == 1) {
            validationInputs = {
                ...validationInputs,
                operator: formData.operator ? '' : 'Please select Operator'
            }
        }
        if (formData.date_of_manufacture && !moment(formData.date_of_manufacture).isValid()) {
            validationInputs = {
                ...validationInputs,
                date_of_manufacture: 'Enter valid Date of Manufacture'
            }
        }
        if (formData.sold_out_date) {
            const soldOutDate = moment(formData.sold_out_date);
        
            if (!soldOutDate.isValid()) {
                validationInputs = {
                    ...validationInputs,
                    sold_out_date: 'Enter valid Date of Sale'
                };
            } else if (soldOutDate.isAfter(moment())) {
                validationInputs = {
                    ...validationInputs,
                    sold_out_date: 'Date of Sale cannot be a future date'
                };
            }
        }
        
        if (formData?.status?.value == 1) {
            validationInputs = {
                ...validationInputs,
                region: formData.region ? '' : 'Please select Region of Operation'
            }
        }
        if (formData?.status?.value == 1 && formData?.lessee && formData.same_operator_lessee === false && formData?.operator) {
            if (formData.lessee.name == formData.operator.name) {
                validationInputs = {
                    ...validationInputs,
                    operator: 'Operator Should be different from Lessee'
                }
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
                let payload = Object.assign({}, formData);
                payload = {
                    ...payload,
                    position: formData?.position?.value || null,
                    part_number: formData.part_number || null,
                    status: formData.status,
                    sub_status: formData?.sub_status || null,
                    region: formData?.region?.id || null,
                    lessee: {'id':formData.lessee?.id,'name':formData.lessee?.name} || null,
                    operator: {'id':formData.operator?.id,'name':formData.operator?.name}|| null,
                    archive_status: payload.status.value == 5 ? true : false
                }
                if (data == 'notify_email') {
                    payload = {
                        ...payload,
                        notify_email: true
                    }
                }
                setLoading(true)
                globalPostService(`console/add-components/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            toggleModalFn();
                            setLoading(false)
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            trackActivity('Item Added', { page_title: 'Asset List', event_type: `Add ${addAsset.asset_type}`, asset_type: `${addAsset.asset_type}`, asset_name: formData.component_name, event_desc: `Added ${addAsset.asset_type} data from Add ${addAsset.asset_type} form` })
                            setTimeout(() => {
                                if (payload.status == 5) {
                                    browserHistory.push('/archived-assets')
                                } else {
                                    if (location.query.apps && location.query.asset_type) {
                                        getResponseBack()
                                    } else {
                                        browserHistory.push(`/technical-specification/component/${response.data.data.slug}/basic-details`)
                                    }
                                }
                            }, 800);
                        } else {
                            setLoading(false)
                        }
                    })
        } else {
            setError(validationInputs)
        }
    }
    const { jacLeaseStatus, defaultLeaseStatus, thirdParty } = leaseStatus;
    let jetstreamOption;
    if (formData?.status?.value == 1) {
        jetstreamOption = subStatus.onLease;
    } else if (formData?.status?.value == 2) {
        if (formData.ownership_type?.value == 2) {
            jetstreamOption = subStatus.offLease.filter(item => item.value !== '25');
        }else{
            jetstreamOption= subStatus.offLease
        }
    } else if (formData?.status?.value == 17) {
        jetstreamOption = subStatus.sold;
    } else if (formData?.status?.value == 14) {
        jetstreamOption = subStatus.partOut;
    } else if (formData.ownership_type?.value == 3 || formData?.status?.value == 15) {
        jetstreamOption = subStatus.installedOnly;
    } else if (formData?.status?.value == 16) {
        jetstreamOption = subStatus.writtenOff;
    } else {
        jetstreamOption = pltConstants.filter(item => item.type === 'asset_sub_status');
    }
    return (
        <Fragment>
            {pageLoader ? <PageLoader /> : null}

            <Dialog
                open={addAsset.modal}
                onClose={() => toggleModalFn()}
                className='console-management-modal'
                maxWidth={'md'}
            >
                <DialogTitle id="scroll-dialog-title">
                    Add Component
                </DialogTitle>
                <DialogContent dividers={true} className="data-upload-modal">
                    <form>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="manufacturer"
                                    label="Manufacturer"
                                    fullWidth
                                    margin="normal"
                                    value={formData.manufacturer}
                                    onChange={(e, value) => onFieldChange('manufacturer', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    id="serial_number"
                                    label="Component Serial No."
                                    fullWidth
                                    margin="normal"
                                    value={formData.serial_number}
                                    error={error.serial_number ? true : false}
                                    helperText={error.serial_number ? error.serial_number : ''}
                                    onChange={(e, value) => onFieldChange('serial_number', e.target.value)}
                                    onFocus={() => onRestErrorKey('serial_number')}
                                    InputLabelProps={{ shrink: true }}
                                    // inputProps={{ maxLength: 20 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    id="component_name"
                                    label="Component Name"
                                    fullWidth
                                    margin="normal"
                                    value={formData.component_name}
                                    error={error.component_name ? true : false}
                                    helperText={error.component_name ? error.component_name : ''}
                                    onChange={(e, value) => onFieldChange('component_name', e.target.value)}
                                    onFocus={() => onRestErrorKey('component_name')}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        required
                                        margin="normal"
                                        id="date_of_manufacture"
                                        label="Date of Manufacture"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        minDate={moment().subtract(25, 'years')}
                                        value={formData.date_of_manufacture ? formData.date_of_manufacture : null}
                                        error={error.date_of_manufacture ? true : false}
                                        helperText={error.date_of_manufacture ? error.date_of_manufacture : ''}
                                        onChange={(data, value) => { onFieldChange('date_of_manufacture', moment(data).format(backendDateFormat)); onRestErrorKey('date_of_manufacture') }}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="part_number"
                                    label="Part Number"
                                    fullWidth
                                    margin="normal"
                                    value={formData.part_number}
                                    error={error.part_number ? true : false}
                                    helperText={error.part_number ? error.part_number : ''}
                                    onFocus={() => onRestErrorKey('part_number')}
                                    onChange={(e, value) => onFieldChange('part_number', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 20 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={regions}
                                    getOptionLabel={option => option.name}
                                    id="region"
                                    value={formData.region}
                                    onChange={(e, value) => onFieldChange('region', value)}
                                    renderInput={params => <TextField required={formData?.status?.value == 1 ? true : false} {...params} label="Region of Operation" margin="normal" fullWidth InputLabelProps={{ shrink: true }} error={error.region ? true : false} helperText={error.region ? error.region : ''} onFocus={() => onRestErrorKey('region')} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <GoogleLocationField
                                    onFieldChange={onFieldChange}
                                    location={formData.location ? formData.location : ''}
                                />
                            </Grid>
                            <Grid item md={4}>
                                {(() => {
                                    let options = [{ value: 1, label: 'Owned' }, { value: 2, label: 'Managed' }, { value: 3, label: 'Third Party' }];
                                    return (
                                        <Autocomplete
                                            disableClearable
                                            // disabled={!isEdit}
                                            options={!jetStreamInstance ? options.filter(option => option.value !== 3) : options}
                                            getOptionLabel={option => option.label}
                                            name="ownership_type"
                                            value={formData?.ownership_type || null}
                                            onChange={(e, value) => onFieldChange('ownership_type', value)}
                                            renderInput={params => <TextField required {...params} label="Ownership" error={error.ownership_type ? true : false} helperText={error.ownership_type ? error.ownership_type : ''} onFocus={() => onRestErrorKey('ownership_type')} margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    )
                                })()}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={formData.ownership_type?.value === 3 ? thirdParty : formData.ownership_type?.value !== 3 ? jacLeaseStatus : defaultLeaseStatus}
                                    getOptionLabel={option => option.label}
                                    id="status"
                                    value={formData.status}
                                    onChange={(e, value) => onFieldChange('status', value)}
                                    renderInput={params => <TextField required error={error.status ? true : false} helperText={error.status ? error.status : ''} onFocus={() => onRestErrorKey('status')} {...params} label="Lease Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            {formData?.status?.value == 17 ?
                                <Grid item xs={12} md={4}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="sold_out_date"
                                            label="Date of Sale"
                                            format={fieldDateFormat}
                                            fullWidth
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            value={formData.sold_out_date ? formData.sold_out_date : null}
                                            onChange={(data, value) =>{onFieldChange('sold_out_date', moment(data).format(backendDateFormat));onRestErrorKey('sold_out_date')}}
                                            inputVariant='outlined'
                                            error={error.sold_out_date ? true : false}
                                            helperText={error.sold_out_date ? error.sold_out_date : ''}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid> : null
                            }
                            {pltConstants.filter(item => item.type === 'asset_sub_status').length && formData?.status?.value == 5 ? null :
                                <Grid item xs={12} md={4}>
                                    <Autocomplete
                                        options={jetstreamOption}
                                        getOptionLabel={option => option.label}
                                        id="sub_status"
                                        value={formData.sub_status}
                                        onChange={(e, value) => onFieldChange('sub_status', value)}
                                        renderInput={params => <TextField {...params} label="Sub Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="tsn"
                                    label="TSN"
                                    fullWidth
                                    margin="normal"
                                    value={formData.tsn ? formData.tsn : ''}
                                    onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault() }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="csn"
                                    label="CSN"
                                    fullWidth
                                    margin="normal"
                                    value={formData.csn ? formData.csn : ''}
                                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault() }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="average_monthly_hours"
                                    label="Avg. Monthly Hours"
                                    fullWidth
                                    margin="normal"
                                    value={formData.average_monthly_hours ? formData.average_monthly_hours : ''}
                                    onChange={(e) => { regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('average_monthly_hours', e.target.value) : e.preventDefault() }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="average_monthly_cycles"
                                    label="Avg. Monthly Cycles"
                                    fullWidth
                                    margin="normal"
                                    value={formData.average_monthly_cycles ? formData.average_monthly_cycles : ''}
                                    onChange={(e) => { regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('average_monthly_cycles', e.target.value) : e.preventDefault() }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            {formData?.status?.value == 1 ?
                                <>
                                    <Grid item xs={13} md={4}>
                                        <LesseeCreate
                                            options={lessees}
                                            required={formData?.status?.value == 1 ? true : false}
                                            paramsKey='lessee'
                                            optionKey='name'
                                            label='Lessee'
                                            value={formData.lessee ? formData.lessee : null}
                                            onFieldChange={(e, paramsKey, newValue) => onFieldChangeLessee(e, 'lessee', newValue)}
                                            error={error.lessee}
                                            resetErrorKey={() => onRestErrorKey('lessee')}
                                            disabled={formData?.status?.value != 1 ? true : false}
                                        />
                                    </Grid>
                                    {(() => {
                                        let options = [{ label: 'Yes', value: 1 }, { label: 'No', value: 2 }];
                                        let optionValue = formData?.same_operator_lessee ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
                                        return (
                                            <Grid item md={3}>
                                                <Autocomplete
                                                    disableClearable={true}
                                                    options={options}
                                                    getOptionLabel={option => option.label}
                                                    name="same_operator_lessee"
                                                    value={optionValue}
                                                    onChange={(e, value) => onFieldChange('same_operator_lessee', value?.value === 1 ? true : false)}
                                                    renderInput={params => <TextField {...params} label="Operator is Same as Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                                />
                                            </Grid>
                                        )
                                    })()}
                                    {formData.same_operator_lessee === false ?
                                        <Grid item xs={4} md={4}>
                                            <Autocomplete
                                                options={operators}
                                                getOptionLabel={option => option.name}
                                                id="operator"
                                                value={formData.operator}
                                                onChange={(e,value) => onFieldChange('operator', value)}
                                                renderInput={params => <TextField required={formData.same_operator_lessee === false ? true : false} error={formData.same_operator_lessee === false ? error.operator ? true : false : false} helperText={formData.same_operator_lessee === false ? error.operator : ''} onFocus={() => onRestErrorKey('operator')} {...params} label="Operator" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                            />
                                        </Grid> : null
                                    }
                                </> : null
                            }
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => { toggleModalFn(); trackActivity('Item Clicked', { page_title: 'Asset List', event_type: 'Cancel', event_desc: ` Clicked on Cancel Button from Add ${addAsset.asset_type} form` }) }}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={() => { onAddNewAsset(); trackActivity('Item Clicked', { page_title: 'Asset List', event_type: 'Save ', event_desc: `Clicked on Save Button from Add ${addAsset.asset_type} form` }) }}>
                        {isLoading ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default withRouter(AddComponenet);

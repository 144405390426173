import React from 'react';
import { Link, IndexLink, withRouter } from 'react-router';
import { imgStoragePath } from '../../constants';
import homeIcon from '../../shared/assets/img/home_icon.png';
import majorAssembly from '../../assets/img/Major_Assemblies_icon.svg';
import inventoryIcon from '../../console_management/assets/images/inventory_icon.svg';
import { getLocalStorageInfo, checkPermission } from '../utils_v2';
import config from '../../config'
import { halcyonInstance } from '../../shared/components';
const SideNav = ({location}) => {
    console.log('location',location);
    return(
        <div className='primary-side-nav'> 
            <div className='menu-link'>
                <IndexLink to='/' activeClassName="active">
                    <img src={homeIcon} alt='Home' width='25' />
                    <p>Home</p>
                </IndexLink>
            </div>
            <div className='menu-link'>
                <Link to='/assets-listing' className={ (location.pathname.includes('technical-specification') || location.pathname.includes('/assets-listing')) && !location.search?.includes('archived-assets')  ? 'active':''}>
                    <img src={`${imgStoragePath}phase-2/asset_list_icon.png`} alt='Assets' width='20' />
                    <p>Assets</p>
                </Link>
            </div>
            { getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 ) || halcyonInstance ? null : checkPermission('technical_specs', 'major_assemblies', 'R') ?
                <div className='menu-link'>
                    <Link to='/major-assemblies' activeClassName="active">
                        <img src={majorAssembly} alt='Major Assemblies' width='20' />
                        <p>Major Assemblies</p>
                    </Link>
                </div>:null
            }
            { getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 ) || halcyonInstance  ? null : checkPermission('technical_specs', 'inventory', 'R') ?
                <div className='menu-link'>
                    <Link to='/inventories' activeClassName="active">
                        <img src={inventoryIcon} alt='Inventory' width='20' />
                        <p>Inventory</p>
                    </Link>
                </div>:null
            }
            
            { checkPermission('reports', 'custom_reports', 'R') || checkPermission('reports', 'bulk_download', 'R') ?
                <div className='menu-link'>
                    <Link to='/export/reports/' activeClassName="active">
                        <img src={`${imgStoragePath}report_icon.png`} alt='Reports' width='20' />
                        <p>Reports</p>
                    </Link>
                </div>:null
            }
            
            { checkPermission('console', 'lessor_configuration', 'R') || checkPermission('console', 'owner_details', 'R') || checkPermission('console', 'banking_details', 'R') || checkPermission('console', 'distribution_emails', 'R') || checkPermission('console', 'introduction', 'R') || checkPermission('console', 'add_lessee', 'R')|| checkPermission('console', 'contact', 'R') ?
                <div className='menu-link'>
                    <Link to='/configure' className={location.pathname.includes('/configure') ?"active":""}>
                        <img src={`${imgStoragePath}settings.png`} alt='Configure' width='20' />
                        <p>Configure</p>
                    </Link>
                </div>:null
            }
            
            { checkPermission('settings', 'manage_user', 'R') || checkPermission('settings', 'custom_roles', 'R') ?
                <div className='menu-link'>
                    <Link to={checkPermission('settings', 'manage_user', 'R') ?'/users/manage':'/settings/roles'} className={location.pathname.includes('/users') || location.pathname.includes('/settings')?'active':''}>
                        <img src={`${imgStoragePath}setting.svg`} alt='Settings' width='20' />
                        <p>Settings</p>
                    </Link>
                </div>:null
            }
            
            {checkPermission('technical_specs', 'archived_assets', 'R') ?
                <div className='menu-link'>
                    <Link to='/archived-assets'  className={location.search?.includes('archived-assets') || location.pathname?.includes('/archived-assets')?'active':''}>
                        <img src={`${imgStoragePath}phase-2/asset_list_icon.png`} alt='Archived Assets' width='20' />
                        <p>Archived Assets</p>
                    </Link>
                </div>:null
            }
        </div>
    )
}
export default withRouter(SideNav);